import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { IModel } from '../../../momguide-interface';
import { apiProductKeywordPrediction } from '../../apis/productApi';
import * as styles from '../../styles/search/searchResultList.module.css';
import BrandListitem from './BrandListItem';
import CategoryListItem from './CategoryListItem';
import ProductItem from './ProductItem';

const limit = 20;

interface SectionData {
  header: { title: string; count: number };
  data: IModel.CategoryWithChild[] | IModel.Brand[] | IModel.ProductDetail[];
}

export default function SearchResultList(props) {
  const { keyword, withinKeyword, categoryId } = props;
  const isLoading = useRef(false);
  const page = useRef(0);
  const [sectionData, setSectionData] = useState<SectionData[]>([]);

  const onEndReached = async () => {
    // console.log('end')
    if (sectionData?.[2].data.length < (page.current + 1) * limit || isLoading.current) {
      return;
    }
    isLoading.current = true;
    const result = await apiProductKeywordPrediction(keyword, page.current + 1, limit, withinKeyword);

    page.current++;
    const suggestionsData = cloneDeep(sectionData);

    setSectionData((prevSuggestionsData) => {
      suggestionsData[0].data = [...prevSuggestionsData[0].data, ...result.categoryResult.rows]; //0 = 카테고리
      suggestionsData[1].data = [...prevSuggestionsData[1].data, ...result.brandResult.rows]; // 1 = 브랜드
      suggestionsData[2].data = [...prevSuggestionsData[2].data, ...result.productResult.rows]; // 2 = 제품

      return suggestionsData;
    });
    isLoading.current = false;
  };

  const isFindBottom = (el) => {
    console.log(el.getBoundingClientRect().bottom);
    console.log(el.getBoundingClientRect().y);
    console.log(window.innerHeight);
    return el.getBoundingClientRect().bottom <= window.innerHeight + 100;
  };
  const handleNavigation = (e) => {
    const isBottom = isFindBottom(e.target.body);

    if (isBottom) {
      //pagnation
      onEndReached();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleNavigation);

    return () => {
      window.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation]);

  useEffect(() => {
    const init = async () => {
      const values = await apiProductKeywordPrediction(keyword, 0, limit, withinKeyword, categoryId);
      const totlaCount = values.brandResult.count + values.categoryResult.count + values.productResult.count;

      const sectionDataTemp: SectionData[] = [];
      sectionDataTemp.push({
        header: { title: '카테고리', count: values.categoryResult.count },
        data: values.categoryResult.rows,
      });
      sectionDataTemp.push({
        header: { title: '브랜드', count: values.brandResult.count },
        data: values.brandResult.rows,
      });
      sectionDataTemp.push({
        header: { title: '제품', count: values.productResult.count },
        data: values.productResult.rows,
      });
      setSectionData(sectionDataTemp);
    };
    init();
  }, []);

  return (
    <div>
      <ul>
        {sectionData.map((section) => {
          if (section.header.count) {
            return (
              <li className={styles.listHeader} key={section.header.title}>
                <span className={styles.listHeaderText}>
                  {section.header.title} ({section.header.count})
                </span>
                <ul>
                  {section.data.map((item, index) => {
                    if (section.header.title === '제품') {
                      return <ProductItem key={item.id} item={item} path="키워드 검색" />;
                    } else if (section.header.title === '브랜드') {
                      return (
                        <BrandListitem
                          key={item.id}
                          item={item}
                          // onFinish={data => {
                          //   amplitudeLog('검색창 - 검색 결과 클릭', {
                          //     path: '브랜드',
                          //     name: data.brand,
                          //   })
                          // }}
                        />
                      );
                    } else {
                      return <CategoryListItem key={item.id} item={item} section={section} index={index} />;
                    }
                  })}
                </ul>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}
