import { navigate } from 'gatsby-link';
import React from 'react';
import { ObjectFlags } from 'typescript';
import * as styles from '../../styles/search/categoryListItem.module.css';

const CATEGORY_ITEM_HEIGHT = 70;

export default function CategoryListItem(props) {
  const { item, index, section } = props;

  const goToProductsByCategory = () => {
    return navigate(
      `/productResult/${item.id + ',' + item.child?.id}?subCategoryId=${item.child?.child?.id}${
        item.child?.child?.child?.id ? '&subCategoryId2=' + item.child?.child?.child?.id : ''
      }`,
    );
  };

  return (
    <div
      onClick={() => {
        goToProductsByCategory();
      }}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: CATEGORY_ITEM_HEIGHT,
        borderBottom: '0.5px #dadada solid',
        borderBottomWidth: index === section.data.length - 1 ? 0 : 0.5,
        borderBottomColor: '#dadada',
        backgroundColor: '#ffffff',
        cursor: 'pointer',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <img
          style={{
            width: 40,
            height: 40,
            marginLeft: 16,
            marginRight: 16,
            objectFit: 'contain',
          }}
          src={item.child.imgUrl + '?production'}
        />
      </div>
      <div style={{ alignItems: 'center' }}>
        <div style={{ flexDirection: 'row', alignItems: 'center' }}>
          <span
            style={{
              fontSize: 13,
              fontWeight: '400',
              color: '#000000',
            }}
          >
            {item.child?.name}
          </span>
          {item.child?.child && (
            <img
              style={{
                transform: 'rotate(180deg)',
                width: 8,
                height: 8,
                marginRight: 3,
              }}
              src="/images/arrow_left.svg"
            />
          )}
          <span
            style={{
              fontSize: 12,
              fontWeight: 400,
              color: '#898989',
            }}
          >
            {item.child?.child?.name}
          </span>
        </div>
      </div>
    </div>
  );
}
