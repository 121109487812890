import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AppContainerView from '../../components/AppContainerView';

import MobileSearch from '../../components/searchComp/MobileSearch';
import { useSiteMetadata } from 'src/hooks/useSiteMetadata';
import { SEO } from 'src/components/seo';
import { PageProps } from 'gatsby';

export default function Search({ data, location, params, ...rest }: PageProps) {
  const { appLink, appLinkApple, appLinkGoogle, descriptionSearch, siteUrl } = useSiteMetadata();

  const appType = useSelector((state: any) => state.appType);

  return (
    <AppContainerView pageTitle={'제품검색'} appLink={appLink} appLinkApple={appLinkApple} appLinkGoogle={appLinkGoogle}>
      {appType === 'mom' && <MobileSearch location={location} />}
      {appType === 'pet' && <MobileSearch location={location} />}
    </AppContainerView>
  );
}

export const Head = (props: PageProps) => {
  const { descriptionSearch, siteUrl } = useSiteMetadata();

  return (
    <SEO title="검색">
      <meta id="desc" name="description" content={descriptionSearch} />
      <link rel="canonical" href={`${siteUrl}/search/`} />
    </SEO>
  );
};
