import { navigate } from 'gatsby-link';
import React from 'react';
import { apiGetBrandCategory } from '../../apis/categoryApi';
import * as styles from '../../styles/search/brandListItem.module.css';

interface Props {
  item: any;
  onFinish?: (data: any) => void;
}

const BrandListitem = (props: Props) => {
  const { item } = props;

  const onPressBrandItem = async (item) => {
    // dispatch(setSelectedCategoryIndex1Depth(0));
    // onFinish && onFinish({ brand: item.fullName });
    const brandCategories = await apiGetBrandCategory(item.id);
    navigate(`/productResult/brand,${brandCategories[0].id}/?brandId=${item.id}`, {
      state: {
        brand: item,
      },
    });
    // navigation.navigate('CategoryProductResult', {
    //   brand: item,
    //   brandCategories,
    //   logData: { path, 브랜드명: item.fullName },
    //   onPressProduct,
    // });
  };

  return (
    <div
      className={styles.brandListItem}
      onClick={(e) => {
        e.stopPropagation();
        // onPressBrandItem(item)
      }}
    >
      <img className={styles.brandListIcon} src={item.imgUrl + '?production'} />
      <div style={{ flex: 1 }}>
        <span style={{ fontSize: 13, color: '#898989' }}>{item.fullName}</span>
      </div>
    </div>
  );
};

export default BrandListitem;
