import React from 'react';
import { highlightTextSplit } from '../../utils';
import * as styles from '../../styles/search/autoCompleteList.module.css';

const HighlightText = (props) => {
  const { fullText, highlightText } = props;

  return (
    <div className={styles.HighlightText}>
      {highlightTextSplit(fullText, highlightText).map((d, index) => {
        if (d?.toLowerCase().replace(/\s+/g, '') == highlightText?.toLowerCase().replace(/\s+/g, ''))
          return (
            <div key={'highlightText' + index} style={{ fontWeight: 'bold' }}>
              {d}
            </div>
          );
        return (
          <div key={'normalText' + index} style={{ color: '#424242' }}>
            {d}
          </div>
        );
      })}
    </div>
  );
};
const AutoCompleteList = (props) => {
  const { highlightText, suggestionKeyword, onSelectKeyword } = props;

  return (
    <div className={styles.container}>
      {suggestionKeyword.map((item, index) => {
        return (
          <div key={'latestKeyword' + item + index} className={styles.listContainer} onClick={() => onSelectKeyword(item)}>
            <div className={styles.searchWhiteIconBox}>
              <img className={styles.searchWhiteIcon} src="/images/main/search_white.png" />
            </div>
            <div className={styles.HighlightTextContainer}>
              <HighlightText key={'highlightText' + index} fullText={item.replace(/ /g, '\u00A0')} highlightText={highlightText} />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AutoCompleteList;
