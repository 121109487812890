import React, { FormEventHandler, useEffect, useRef, useState } from 'react';
import { Mobile, PC, useIsMobile } from '../mediaQuery';
import * as styles from '../../styles/search/mobileSearch.module.css';
import { setBaseUrl } from '../../apis/commom';
import AutoCompleteList from './HighlightText';
import { getKeywordSuggestion } from '../../apis/productApi';
import MobileTemplate from '../MobileTemplate';
import SearchResultList from './SearchResultList';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, setAppType } from '../../state/rootStore';

import { fetchGetCategory } from '../../state/rootStore';
import { Link, navigate } from 'gatsby';
import { apiUrl } from '../../data';
// import { useQueryParam, StringParam } from 'use-query-params'
import { amplitudeLog } from '../../Lib/ampletude';
import { getPlatform } from '../../utils';

const Header = ({ bold }) => {
  const isMagazineBold = bold === 'magazine';
  const isEventBold = bold === 'event';
  const isSearchBold = bold === 'search';
  const isAnalysisBold = bold === 'analysis';

  const appType = useSelector((state: RootState) => state.appType);

  return (
    <Mobile className={styles.mobile}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          height: '100%',
        }}
        className={styles.menuText}
      >
        <Link to="/">
          <img alt="맘가이드 로고" className={styles.logo} src="/images/logo_mint_eng.png" />
        </Link>
        <Link to="/magazines/" style={{ color: '#3a3a3a' }}>
          <div className={isMagazineBold ? styles.bold : ''}>매거진</div>
        </Link>
        <Link to="/events/" style={{ color: '#3a3a3a' }}>
          <div className={isEventBold ? styles.bold : ''}>이벤트</div>
        </Link>
        {/* <Link to="/analysis" style={{ color: '#3a3a3a' }}>
          <div className={isAnalysisBold ? styles.bold : ''}>1:1 성분분석</div>
        </Link> */}
        <Link to="/search/">
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #E5E5E5',
              borderRadius: '5px',
              width: '95px',
              height: '32px',
            }}
            className={isSearchBold ? (appType === 'pet' ? styles.searchTextbold : styles.bold) : ''}
          >
            {/* <div className={styles.searchLogo}>
              <SearchIcon
                size={16}
                color={
                  isSearchBold
                    ? appType === 'pet'
                      ? '#fac53e'
                      : '#88D8CF'
                    : undefined
                }
              />
            </div> */}
            <div>제품 검색</div>
            <img
              alt="검색 로고"
              className={styles.searchLogo}
              src="/images/header/search_lens.png"
              style={{
                marginLeft: '7px',
                marginRight: 0,
              }}
            />
          </div>
        </Link>
      </div>
    </Mobile>
  );
};

export const MainCategoryItem = (props) => {
  const { item, index, isSelected, onClick } = props;
  return (
    <li
      className={styles.categoryTextView}
      onClick={(e) => {
        e.stopPropagation();
        if (!item.id) {
          // Alert.alert('안내', category.name + ' 카테고리의 정보를 업데이트 중입니다. 조만간 찾아뵐게요!');
          return;
        }

        onClick(index);
      }}
    >
      <img src={item.imgUrl} style={{ width: 47, height: 47, opacity: isSelected ? 1 : 0.3 }} />
      <span className={styles.categoryText} style={{ color: isSelected ? '#898989' : '#898989' + 60 }}>
        {item.name}
      </span>
    </li>
  );
};

export const SubCategoryItem = (props) => {
  const { item = {}, index, find2Depth, mainCategoryItem0, mainCategoryItem1, onFinish, searchBrand } = props;
  const [isOpen, setIsOpen] = useState(find2Depth === index);
  const dispatch = useDispatch();
  const hasChildren = item?.children?.length > 0;

  const onPressSubCategory = (subCategoryId: number) => {
    if (hasChildren) {
      toggleOpen();
    } else {
      if (onFinish) {
        onFinish();
      }
      navigate(
        `/productResult/${(searchBrand ? 'brand,' : '') + mainCategoryItem0.id + ',' + mainCategoryItem1.id}?subCategoryId=${item.id}`,
        {
          state: { brand: searchBrand },
        },
      );
    }
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={styles.subCategoryView}>
      <div
        className={styles.subCategoryTextView}
        onClick={(e) => {
          e.stopPropagation();
          onPressSubCategory(item.id);
        }}
      >
        <span className={styles.subCategoryText} style={{ fontWeight: index === 0 ? 700 : 400 }}>
          {item.name}
        </span>
        {hasChildren ? (
          <img
            style={{ width: 15, height: 15 }}
            className={isOpen ? styles.arrowUp : styles.arrowDown}
            // src={isOpen ? images.up : images.down}
            // resizeMode="contain"
            src="/images/arrow_left.svg"
          />
        ) : null}
      </div>
      <div className={isOpen ? styles.subCategrySubItemView : styles.subCategrySubItemViewClose}>
        {item?.children.map((data, childrenIndex) => {
          return (
            <Link
              key={data.id}
              to={`/productResult/${(searchBrand ? 'brand,' : '') + mainCategoryItem0.id + ',' + mainCategoryItem1.id}?subCategoryId=${
                item.id
              }${item.id === data.id ? '' : '&subCategoryId2=' + data.id}`}
              state={{
                brand: searchBrand,
              }}
            >
              <div
                style={{
                  height: 35,
                  justifyContent: 'center',
                  flex: 1,
                  paddingLeft: 26,
                  paddingTop: 10,
                  cursor: 'pointer',
                }}
              >
                <span
                  style={{
                    fontSize: 12,
                    color: '#898989',
                  }}
                >
                  {data.name}
                </span>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default function MobileSearch(props: any) {
  const { location } = props;
  const paramsObj = new URLSearchParams(location.search);
  // const [keywordParam, setKeywordParam] = useQueryParam('keyword', StringParam)
  const [keywordParam, setKeywordParam] = useState(paramsObj.get('keyword'));
  const isMobile = useIsMobile();
  const [keyword, setKeyword] = useState(keywordParam || '');
  const [categories, setCategories] = useState([]);
  const [selectedCategoryIndex1Depth, setSelectedCategoryIndex1Depth] = useState(0);
  const [suggestionKeyword, setSuggestionKeyword] = useState([]);
  const [isShowResultPage, setIsShowResultPage] = useState<boolean>(false);
  const [selectedCategoryIndex0Depth, setSelectedCategoryIndex0Depth] = useState(0);

  const keywordRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const appType = useSelector((state: RootState) => state.appType);

  useEffect(() => {
    const init = async () => {
      if (appType !== 'pet') {
        setBaseUrl(apiUrl);
      } else {
        setBaseUrl(apiUrl + '/pet');
      }
      const res = await dispatch(fetchGetCategory());
      setCategories(res.payload);
      setSelectedCategoryIndex1Depth(0);
      if (keyword) onClickSuggestionKeyword(keyword);
    };
    init();
  }, [appType]);

  const onTextClear = () => {
    setKeyword('');
  };

  const changeTab = (index: number) => {
    location.search = '';
    setKeywordParam(undefined);
    // history.pushState(null, "", `/search`)
    if (index === 0) {
      dispatch(setAppType('mom'));
    }
    if (index === 1) {
      amplitudeLog('펫가이드 탭', { type: getPlatform() });
      dispatch(setAppType('pet'));
    }
  };

  const onChangeText = async (text: string) => {
    setKeyword(text);

    if (text) {
      const suggestionKeywordRes = await getKeywordSuggestion(text);
      setSuggestionKeyword(suggestionKeywordRes.suggestion || []);
    }
  };

  const onClickSuggestionKeyword = (keyword: string) => {
    setKeyword(keyword);

    if (keyword) setIsShowResultPage(true);
    else setIsShowResultPage(false);

    history.replaceState(null, '', `/search?keyword=${keyword}`);
  };

  const listComponent = () => {
    if (isShowResultPage) {
      return <SearchResultList keyword={keyword} onPressProduct={() => {}} />;
    }

    if (keyword) {
      return (
        <AutoCompleteList
          highlightText={keyword}
          suggestionKeyword={suggestionKeyword}
          onSelectKeyword={(keyword) => {
            onClickSuggestionKeyword(keyword);
          }}
        />
      );
    } else {
      return (
        <div>
          <div style={{ display: 'flex', height: 40 }}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: selectedCategoryIndex0Depth === 0 ? 'var(--emerald)' : '#f3f3f3',
                cursor: 'pointer',
              }}
              onClick={async () => {
                setSelectedCategoryIndex0Depth(0);
                setSelectedCategoryIndex1Depth(0);
              }}
            >
              <span
                style={{
                  fontSize: 13,
                  fontWeight: 700,
                  color: selectedCategoryIndex0Depth === 0 ? '#ffffff' : 'var(--lightDarkGray)',
                }}
              >
                {categories?.[0]?.name}
              </span>
            </div>
            {appType === 'mom' && (
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: selectedCategoryIndex0Depth === 1 ? 'var(--emerald)' : '#f3f3f3',
                  cursor: 'pointer',
                }}
                onClick={async () => {
                  setSelectedCategoryIndex0Depth(1);
                  setSelectedCategoryIndex1Depth(0);

                  amplitudeLog(categories[1]?.name + '탭', {
                    type: getPlatform(),
                  });
                }}
              >
                <span
                  style={{
                    fontSize: 13,
                    fontWeight: 700,
                    color: selectedCategoryIndex0Depth === 1 ? '#ffffff' : 'var(--lightDarkGray)',
                  }}
                >
                  {categories[1]?.name}
                </span>
              </div>
            )}
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <ul className={styles.categoryUl}>
              {categories?.[selectedCategoryIndex0Depth]?.children.map((item, index) => {
                return (
                  <MainCategoryItem
                    key={item.id}
                    item={item}
                    index={index}
                    isSelected={selectedCategoryIndex1Depth === index}
                    onClick={(index) => {
                      setSelectedCategoryIndex1Depth(index);
                    }}
                  />
                );
              })}
            </ul>
            <ul style={{ flex: 1 }} className={styles.subCategoryUl}>
              {categories?.[selectedCategoryIndex0Depth]?.children[selectedCategoryIndex1Depth]?.children.map((item, index) => {
                return (
                  <SubCategoryItem
                    item={item}
                    index={index}
                    key={item.id}
                    mainCategoryItem0={categories?.[selectedCategoryIndex0Depth]}
                    mainCategoryItem1={categories?.[selectedCategoryIndex0Depth]?.children[selectedCategoryIndex1Depth]}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      );
    }
  };

  return (
    <MobileTemplate>
      <div
        style={{
          position: 'sticky',
          top: isMobile ? '0px' : '90px',
          background: '#fff',
          zIndex: 1,
        }}
      >
        {isMobile ? (
          <Header bold="search" />
        ) : (
          <div
            style={{
              display: 'flex',
            }}
          >
            <img className={styles.webHeader} alt="맘가이드 로고" src="/images/logo_mint_eng.png" />
          </div>
        )}
        <div className={styles.menuBar}>
          <ul className={styles.tabs}>
            <li className={appType === 'mom' ? styles.active : styles.inactive} onClick={() => changeTab(0)}>
              맘가이드
            </li>
            <li className={appType === 'pet' ? styles.petActive : styles.inactive} onClick={() => changeTab(1)}>
              펫가이드
            </li>
          </ul>
        </div>
        <div className={styles.inputContainer}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onClickSuggestionKeyword(keyword);
              keywordRef.current?.blur();
            }}
          >
            <input
              ref={keywordRef}
              type="text"
              autoComplete="off"
              className={styles.inputBox}
              placeholder="제품명/브랜드명을 입력해주세요."
              value={keyword}
              onChange={(e) => onChangeText(e.target.value)}
              onFocus={() => setIsShowResultPage(false)}
              onSubmit={() => onClickSuggestionKeyword(keyword)}
            />
          </form>
          {!!keyword && <button className={styles.clearButton} onClick={onTextClear} />}
        </div>
      </div>
      {listComponent()}
    </MobileTemplate>
  );
}
