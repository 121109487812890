import React, { useEffect, useState } from 'react';
import * as styles from '../../styles/search/productItem.module.css';
import StarRate from '../StarRate';
import { Link, navigate } from 'gatsby';
import { useSelector } from 'react-redux';
import { RootState } from '../../state/rootStore';

const ProductItem = (props) => {
  const { item, path } = props;
  const appType = useSelector((state: RootState) => state.appType);
  const RankRateComponent = (props) => {
    const { style, color = '#FF7272', size = 10, rate } = props;

    const triangleColor = (rate: number) => {
      return rate < 0 ? '#52A9FA' : '#FF7272';
    };

    const RateText = ({ rate }: { rate: number }) => {
      if (rate === 99999) {
        //NEW 랭킹에 새로들어온 아이는 99999로옴
        return <span style={{ fontSize: 9, color: '#FF7272', marginLeft: 2 }}>NEW</span>;
      }

      return <span style={{ fontSize: 9, color: '#898989', marginLeft: 2 }}>{Math.abs(rate)}</span>;
    };
    if (!rate) return null;

    return (
      <div
        style={{
          position: 'absolute',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          left: 45,
        }}
      >
        {rate !== 99999 && (
          <div
            className={styles.triangleUp}
            style={{
              borderLeftWidth: size,
              borderRightWidth: size,
              borderBottomWidth: size * 2,
              borderBottomColor: triangleColor(rate),
              transform: `rotate(${rate < 0 ? '180deg' : '0deg'})`,
            }}
          />
        )}
        <RateText rate={rate} />
      </div>
    );
  };

  const getRankComp = () => {
    if (!item.rank) {
      return <span style={{ color: '#dadada' }}>-</span>;
    }
    // /static/images/main/rank/rank1.png
    if (item.rank < 4) {
      return (
        <>
          <img src={`/images/main/rank/rank${item.rank}.png`} style={{ width: 25, height: 28 }} />
          <RankRateComponent size={3} rate={item.rankDiff} />
        </>
      );
    }

    return (
      <>
        <div style={{ display: 'flex', width: 25, justifyContent: 'center' }}>
          <span style={{ fontWeight: 700, color: '#424242', fontSize: 12 }}>{item.rank || '-'}</span>
        </div>
        <RankRateComponent size={3} rate={item.rankDiff} />
      </>
    );
  };

  const gardeImg = `/images/main/grade/${item?.ProductProperty?.productGrade}_grade${
    appType === 'pet' && item?.ProductProperty?.productGrade === 'V' ? 'pet' : ''
  }.png`;

  return (
    <li style={{ listStyle: 'none' }}>
      <div
        className={styles.productListItem}
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/productDetail/${item.id}`, { state: { path } });
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'inherit',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {getRankComp()}
            <img className={styles.productImg} src={item.imgUrl + '?production'} />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className={styles.brandText}>{item.brand}</span>
            <span className={styles.nameText}>{item.name}</span>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: 1,
              }}
            >
              <StarRate maxRating={5} gap={1} rating={item.score} size={13} />
              <span className={styles.scoreText}>{item.score}</span>
              <span className={styles.scoreCountText}>({item.scoreCnt})</span>
              {!!item.ProductProperty?.isDiscontinued && (
                <img className={styles.issueIcon} src={'/images/main/extinction.png'} alt="단종" />
              )}
              {item.ProductProperty?.issueDescription != null && (
                <img
                  style={{
                    marginLeft: item.ProductProperty?.isDiscontinued ? 3 : 0,
                  }}
                  className={styles.issueIcon}
                  src={'/images/main/issue.png'}
                  alt="이슈"
                />
              )}
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', position: 'relative' }}>
          {item?.ProductProperty?.isCertified ? (
            <img className={styles.gradeIcon} src={'/images/main/grade/A_gradeisCertified.png'} />
          ) : (
            <img className={styles.gradeIcon} src={gardeImg} />
          )}
          <div style={{ position: 'absolute' }}>
            {item?.ProductProperty?.isCertified ? (
              <img
                style={{
                  width: '34px',
                  height: 'auto',
                  position: 'relative',
                  left: -4,
                  top: -4,
                }}
                src={`/images/main/grade/${appType}_v_grade_edge.png`}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </li>
  );
};
export default ProductItem;
